



h1,h2,h3,h4,h5{
	font-family: 'Varela Round', sans-serif;
}

body{
	font-family: 'Nunito Sans', sans-serif;
	background-color: $blue-dark;
	color:#ccc;
	a{
		color:#ccc;

		&.active{
			color: $blue;;
		}
		&:hover{
			color: $white;
			
		}
	}
}



.main{
	
	position:relative;
	z-index:1;
	background-image: url("../img/bg-btm.jpg");
	
	background-size:600px;
	background-repeat:no-repeat;
	background-position:20% top;
	

	@include breakpoint(medium) {
		background-repeat:no-repeat;
		background-position:top center ;
		background-size:100%;
		min-height:800px;
	}
	
	.content{
		padding:10em 3em;
		padding-bottom: 2em;
		@include breakpoint(medium) {
			padding:2em 2.5em;
		}
		p{
			
			
			font-size: 1.1em;
			@include breakpoint(large) {
				padding-right: 1em;
			}
			@include breakpoint(large) {
				padding-right: 5em;
			}
		}
	}
}
