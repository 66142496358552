



.topnav{
	position:fixed;
	top:0;
	width:100%;


	.navcontainer{
		@include grid-row;
		
		height:166px;
		@include breakpoint(medium) {
			height:26vw;
			max-height:390px;
		}
		nav{
			 @include grid-col-size(50%);
			 @include grid-col-pos(6);

		}
	}
}

.main{
	
	margin-top: 166px;
	@include breakpoint(medium) {
		margin-top: 26vw;
	}
	@include breakpoint(1500px) {
		margin-top: 390px;
	}


	.main-inner{
		@include grid-row;
	}
	.content{
		@include grid-col-size(100%);
		@include grid-col-pos(0);

		@include breakpoint(medium) {
			@include grid-col-size(50%);
			@include grid-col-pos(6);
		}

	}
}

.reel{
	.reel-inner{
		width: 80%;
		margin:0 auto;
	}
	.reel-item{
		@include grid-col-size(12);


		@include breakpoint(medium) {
			@include grid-col-size(6);
		}
		@include breakpoint(large) {
			@include grid-col-size(4);
		}
	}
}

.project{
	.project-inner{
		width: 75%;
		margin:0 auto;
	}
	.details,
	.description{
		@include grid-col-size(12);
		@include breakpoint(medium) {
			@include grid-col-size(6);
			float: left;
		}
	}
}