

/* Fonts */
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Comfortaa|Didact+Gothic|Nunito|Varela+Round');



/*  Colours */
$white: #fff;
$black: #000;
$blue: #3bb4f9;
$blue-dark:#050617;;
$pink: #ff04ff;




/* Animate.css  */
$use-fadeIn: true;
$use-fadeOut: true;
$use-fadeInUp: true;
$use-fadeInDown: true;

$use-slideInUp: true;


/* $use-slide-in           :   $use-slide !default;
$use-slideIn			:   $use-slide-in !default;
$use-slideInUp			:   $use-slide-in !default;
$use-slideInDown		:   $use-slide-in !default;
$use-slideInLeft		:   $use-slide-in !default;
$use-slideInRight		:   $use-slide-in !default; */


// Overides the default variable in plyr library
$plyrCol:$blue;