


.reel{

	position: relative;
	z-index: 20;
	background-color: $blue-dark;
	border-bottom:1px solid lighten($blue-dark,10);
	padding:2em 0;
	overflow:auto;
	@include box-shadow(15px, 0, 30px, rgba(black,1));
	@include animate;

	&:hover{
		@include animate;
		@include box-shadow(15px, 0, 150px, rgba($pink,0.3));
		background-color: darken($blue-dark,2);
		border-bottom:1px solid $pink;
	}

	p{
		margin: 0;
		padding:2em;
	}

	.reel-item{
		float: left;
		img{
			width: 100%;
		}
	}
	a{

		font-family: 'Varela Round', sans-serif;
		overflow: hidden;
		position:relative;
		display: block;
		

		&:before{
			@include animate;
			content: "";
			display: block;
			height: 2px;
			width: 0%;
			background-color:$white;
			position:absolute;
			bottom:0;
		}

	}
	a:hover{
		border:none;
		img{
			opacity: 0.2;
			@include animate;
		}

		&:before{
			@include animate;
			background-color:$blue;
			width: 100%;
		}
	}


	img{
		@include animate;
	}
	.details{
		@include vertical-align;
		@include animate;
		text-align: center;
		width: 100%;
		color: #ccc;
		opacity: 0;

	}
	.title{
		font-weight: bold;
		text-transform: uppercase;
		@include translate(0,-0.8em);
		@include animate;
	}
	.client{
		@include translate(0,0.8em);
		@include animate;
	}

	a:hover{
		.details{
			opacity: 1;
			@include animate;
		}
		.title{
			@include translate(0,0em);
			@include animate;
		}
		.client{
			@include translate(0,0em);
			@include animate;
		}
		img{
			@include scale(1.2);
			@include animate;
		}
	}

}