/* 
.plyr__play-large{
	background:red;
}

.plyr__progress--played, 
.plyr__volume--display{
	color:red;
}
.plyr__controls button.tab-focus:focus, 
.plyr__controls button:hover{
	background:red;
} */


.plyr__video-wrapper{
	//background-color: none;
	//opacity:0.2;
}
iframe{
	
}