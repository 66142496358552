


.topnav{
	z-index:10;
	background-image: url("../img/bg-top.jpg");
	@include box-shadow(15px, 0, 30px, rgba(black,1));

	background-size:cover;
	background-repeat:no-repeat;
	background-position:20% center;

	@include breakpoint(medium) {
		background-size:cover;
		background-repeat:no-repeat;
		background-position:bottom center;
	}

	.navcontainer{
		position:relative;
		overflow: auto;
	}
	nav{
		@include vertical-align;
		margin-top: 2em;

		ul{
			display: none;
			@include breakpoint(medium) {
				display: block;
			}
		}
	}
	
}


nav{

	ul{

		font-family: 'Varela Round', sans-serif;
		text-transform: uppercase;
		list-style-type: none;
		margin: 0;
		padding:0;

		font-size: 0.8em;
		@include breakpoint(medium) {
			font-size: 0.8em;
			padding-left: 3.5em;
			letter-spacing: 0.2em;
		}
		@include breakpoint(large) {
			font-size: 1.1em;
			padding-left: 2.5em;
			letter-spacing: 0.3em;
		}

		li{
			display: inline-block;
			
			
			@include breakpoint(medium) {
				margin-right: 1.5em;
			}
			@include breakpoint(large) {
				margin-right: 2em;
			}
		}
		a{
			&:before{
				@include animate;
				content: "";
				display: block;
				background-color: $white;
				height: 2px;
				width: 0%;
				position:relative;
				top: 1.8em;
			}
		}
		a:hover{
			&:before{
				@include animate(0.2s);
				background-color: $blue;
				width: 97%;
			}
		}
	}
	.active{
		a{
			color: $blue;
		}
	}
	

}

.footer-nav{
	text-align: center;
	padding:2em 0;

	ul{
		display: block;
		@include breakpoint(medium) {
			display: none
		}
	}
	li{
		display: inline-block;
		margin:0 2em;
		
	}
}