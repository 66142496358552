

.project{
	img{
		width: 100%;
	}

	.project-video{
		width: 100%;
		margin: 0 auto;
		padding-top: 1em;
	}
	.project-info{
		margin-top: 2em;
	}

	h1{
		text-transform: uppercase;
		font-size: 1.5em;
	}
	h2{
		font-size: 1.1em;

	
		
	}


}